import React from "react";
import StoreScreen from "./StoreScreen";

function App() {
  
  return (
    <React.Fragment>
      <StoreScreen />
    </React.Fragment>
  );
}

export default App;
