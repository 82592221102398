
export const PROJECT_FIDELIA = [
    {
        name: "MAIN-APP",
        appCenterWindows: "FS-Main-app-windows",
        appCenterAndroid: "FS-Main-app-android",
        android: true,
        ios: false,
        windows: true
    },
    {
        name: "CHECK-IN",
        appCenterAndroid: "FS-Checkin-tablet",
        appCenterWindows: "FS-Checkin-windows",
        android: true,
        ios: false,
        windows: true
    },

]