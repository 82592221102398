import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Store from "./Store";
import { Container, FormControl, InputLabel, NativeSelect } from "@mui/material";
import { PROJECT_FIDELIA } from "./utils/Constant";

export default function StoreScreen() {
  const [value, setValue] = React.useState("1");
  const [projectGSM, setProjectGSM] = React.useState(PROJECT_FIDELIA[0]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const useChangeProject = (event: any) => {
    if (event.target.value) {
      const projectSelect = PROJECT_FIDELIA.find(item => item.name === event.target.value)
      if (projectSelect) {
        setProjectGSM(projectSelect)
      }
    }
  }

  React.useEffect(() => {
    if (projectGSM.windows) {
      setValue("1")
    } else if (projectGSM.android) {
      setValue("2")
    } else if (projectGSM.ios) {
      setValue("3")
    } else {
      setValue("1")
    }
  }, [projectGSM])

  return (
    <Container maxWidth="xl" style={{ padding: 20 }}>
      <img
        src={require('./image/logo_fidelia.png')}
        width="100"
        style={{ marginTop: -20 }}
        alt="logo"
      />
      <FormControl style={{ marginLeft: 50, marginBottom: 20 }}>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Project
        </InputLabel>
        <NativeSelect
          defaultValue={projectGSM.name}
          inputProps={{
            name: 'age',
            id: 'uncontrolled-native',
          }}
          onChange={useChangeProject}
        >
          {PROJECT_FIDELIA.map(item => <option key={item.name} value={item.name}>{item.name}</option>)}
        </NativeSelect>

      </FormControl>

      <Box sx={{ width: "100%", height: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
              {projectGSM.windows && <Tab label="Windows" value="1" />}
              {projectGSM.android && <Tab label="Android" value="2" />}
              {projectGSM.ios && <Tab label="IOS" value="3" />}
            </TabList>
          </Box>
          {
            projectGSM.windows &&
            <TabPanel value="1">
              <Store key={1} platform={"windows"} projectGSM={projectGSM} />
            </TabPanel>
          }

          {
            projectGSM.android &&
            <TabPanel value="2">
              <Store key={2} platform={"android"} projectGSM={projectGSM} />
            </TabPanel>
          }
          {
            projectGSM.ios &&
            <TabPanel value="3">
              <Store key={3} platform={"ios"} projectGSM={projectGSM} />
            </TabPanel>
          }

        </TabContext>
      </Box>
    </Container>

  );
}
