/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import {
  Button,
  Chip,
  Container,
  Grid,
  Link,
  Modal,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import moment from "moment";
import QRCode from "react-qr-code";
import { Circles } from 'react-loader-spinner'

const instanceAxios = axios.create({
  baseURL: "https://api.appcenter.ms/v0.1/apps/Fidelia/",
  timeout: 1000000,
  headers: {
    accept: "application/json",
    "X-API-Token": "cf7b0d29dd5dcad32918329b4a4dbde176970a38",
  },
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MAX_LENGTH = 10;

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width };
};

function RenderRow(props: any) {
  const { data, onDonwload } = props;
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 1024;
  const {
    release_notes = "",
    short_version,
    version,
    uploaded_at,
    distribution_groups,
    id,
  } = data || {};

  const splitNoted = release_notes.split("NOTED:");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fefefe",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
  }));

  const useDonwloadApp = () => {
    onDonwload && onDonwload(data);
  };

  return (
    <Box sx={{ flexGrow: 1, height: "100%" }} style={{ minHeight: '100%' }} >
      <Item>
        <Grid container>
          {isMobile ? null : (
            <Grid item xs={2} mt={3}>
              <Chip
                label={<strong>{distribution_groups[0].name}</strong>}
                color={
                  distribution_groups[0].name === "Production" ? "primary"
                    : distribution_groups[0].name === "Staging" ? "success"
                      : distribution_groups[0].name === "Store" ? "error" : "default"
                }
              />
            </Grid>
          )}
          <Grid item xs={isMobile ? 12 : 7}>
            <Stack>
              <Stack direction="row">
                <Typography variant="h6" color={"#444444"}> <strong>{`${splitNoted[0]}`}</strong></Typography>
              </Stack>
              <Stack direction="row">
                <Typography variant="body1">{`Noted: ${splitNoted[1]}`}</Typography>
              </Stack>
              <Typography variant="body1" color={"#444444"}>
                <strong>
                  Version: {short_version} ({version}) - id: {id}
                </strong>
              </Typography>
              <Typography variant="body1" color={"#444444"}>
                <strong>
                  Date:  {moment(uploaded_at).format("HH:mm, DD/MM/yyyy")}
                </strong>
              </Typography>
            </Stack>
          </Grid>
          {isMobile ? null : (
            <Grid item xs={3} mt={3}>
              <Button size="small" variant="outlined" onClick={useDonwloadApp}>
                Download
              </Button>
            </Grid>
          )}
          {isMobile ? (
            <Grid container mt={3}>
              <Grid item xs={6}>
                <Chip
                  label={<strong>{distribution_groups[0].name}</strong>}
                  color={
                    distribution_groups[0].name === "Production" ? "primary" : distribution_groups[0].name === "Staging" ? "success" : "default"
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={useDonwloadApp}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Item>
    </Box>
  );
}

const Store = ({ platform, projectGSM }: any) => {
  const [apps, setApps] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const installUrl = React.useRef<any>();
  const downloadUrl = React.useRef<any>();

  const getAppCenter = React.useCallback(async () => {
    try {
      const baseUrl =
        platform === "android" ? projectGSM.appCenterAndroid :
          platform === "windows" ? projectGSM.appCenterWindows : projectGSM.appCenterIos
      const response = await instanceAxios.get(`${baseUrl}/releases`);
      const { data } = response || {};
      const Development: any[] = [];
      const Staging: any[] = [];
      const Production: any[] = [];
      const Sandbox: any[] = [];
      const Store: any[] = [];
      data.forEach((item: any, index: any) => {
        const { distribution_groups = [] } = item || {}
        if (distribution_groups[0]?.name) {
          if (Development.length < MAX_LENGTH && distribution_groups[0].name === 'Development') {
            Development.push(item)
          }
          if (Staging.length < MAX_LENGTH && distribution_groups[0].name === 'Staging') {
            Staging.push(item)
          }
          if (Production.length < MAX_LENGTH && distribution_groups[0].name === 'Production') {
            Production.push(item)
          }
          if (Sandbox.length < MAX_LENGTH && distribution_groups[0].name === 'Sandbox') {
            Sandbox.push(item)
          }
          if (Store.length < MAX_LENGTH && distribution_groups[0].name === 'Store') {
            Store.push(item)
          }
        }
      });
      const FideliaStore = [...Development, ...Staging, ...Production, ...Sandbox, ...Store];
      const arrayAppCenter = [] as any;
      FideliaStore.forEach(async (item: any, index: any) => {
        const { id } = item || {};
        if (id) {
          const responseDetail = await instanceAxios.get(
            `${baseUrl}/releases/${id}`
          );
          const { data: dataResponseDetail } = responseDetail || {};
          arrayAppCenter.push(dataResponseDetail);
        }
        if (FideliaStore.length === arrayAppCenter.length) {
          const sortApp = arrayAppCenter.sort((a: any, b: any) => {
            return b.id - a.id;
          });
          setApps(sortApp);
          // localStorage.setItem(platform, JSON.stringify(sortApp));
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [projectGSM]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    installUrl.current = null;
    downloadUrl.current = null;
    setOpen(false);
  };
  const useDownload = (data: any) => {
    const { install_url, download_url } = data || {};

    installUrl.current = install_url;
    downloadUrl.current = download_url;
    handleOpen();
  };

  React.useEffect(() => {
    setApps([])
    getAppCenter();
  }, [projectGSM]);

  if (apps.length === 0) {
    return <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={10}
    >
      <Circles color="#05BB75" height={80} width={80} />
    </Box>
  }

  return (
    <Container fixed>
      {apps.map((item, index) => (
        <RenderRow key={index} data={item} onDonwload={useDownload} />
      ))}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack>
            <QRCode value={installUrl.current ? installUrl.current : ""} />
            <Link href={installUrl.current}>Install APP</Link>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
};

export default Store;
